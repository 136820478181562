/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
// global.scss
@import '~swiper/swiper.min.css';
@import '~@ionic/angular/css/ionic-swiper.css';

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

/* font css */
@import url('https://fonts.google.com/specimen/Montserrat');
@import url('https://fonts.google.com/specimen/Barlow+Condensed?query=barlow+condensed');
@import "~cropperjs/dist/cropper.css";

@font-face {
  font-family: 'BarlowCondensedRegular';
  src: url('./assets/fonts/BarlowCondensed-Regular.ttf');
}

@font-face {
  font-family: 'BarlowCondensedSemiBold';
  src: url("./assets/fonts/BarlowCondensed-SemiBold.ttf");
}

@font-face {
  font-family: 'MontserratRegular';
  src: url('./assets/fonts/Montserrat-Regular.ttf');
}

.barlowCondensedRegular {
  font-family: BarlowCondensedRegular !important;
}

.barlowCondensedSemiBold {
  font-family: BarlowCondensedSemiBold !important;
}

.montserratRegular {
  font-family: MontserratRegular !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fw-bold {
  font-weight: bold !important;
}

.text-black {
  color: #3E3E3E !important;
}


/** ---------------------------------------------------------------------
 ** ---------------------------------------------------------------------
 * 1. css for input/textarea, checkbox, select, button segment style
 ** ---------------------------------------------------------------------
 ---------------------------------------------------------------------**/
.text-input-list {
  // add to a div to container the element
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

/** ---------------------------------------------------------------------
 ** ---------------------------------------------------------------------
 * 2. css for button
 ** ---------------------------------------------------------------------
 ---------------------------------------------------------------------**/
.button-type-80-width-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ion-button {
    font-family: BarlowCondensedSemiBold;
    letter-spacing: 1px;
    width: 90%;
    max-width: 400px;
    margin: 5px;
  }
}


/** ---------------------------------------------------------------------
 ** ---------------------------------------------------------------------
 * 3. css for card
 ** ---------------------------------------------------------------------
 ---------------------------------------------------------------------**/
.card-5-radius {
  border-radius: 5px;
}

/** ---------------------------------------------------------------------
 ** ---------------------------------------------------------------------
 * 4. css calendar
 ** ---------------------------------------------------------------------
 ---------------------------------------------------------------------**/
ion-datetime {
  border-radius: 5px;
  font-family: MontserratRegular;
  margin-left: auto;
  margin-right: auto;
  --background: #f4f5f8;
}

/** ---------------------------------------------------------------------
 ** ---------------------------------------------------------------------
 * 5. css for product, container
 ** ---------------------------------------------------------------------
 ---------------------------------------------------------------------**/
/** control the responsive of product card **/
.product-card-list-wrap {
  display: flex;
  flex-wrap: wrap;
}

/** applied to page html **/
.product-card-container {
  width: 50%;
}

@media (min-width: 768px) {
  .product-card-container {
    width: 25%;
  }
}

@media (min-width: 992px) {
  .product-card-container {
    width: 20%;
  }
}

/** image container **/
.product-card {
  box-shadow: none;
}

.product-card img {
  border-radius: 10px;
  width: 100%;
  aspect-ratio: 1/1.4;
  overflow: hidden;
  object-fit: cover;
  background-position: center;
}

/** ---------------------------------------------------------------------
 ** ---------------------------------------------------------------------
 * 6. camera
 ** ---------------------------------------------------------------------
 ---------------------------------------------------------------------**/
//for debug the black camera in dark mode
body.camera-active {
  --background: transparent;
  --ion-background-color: transparent;
}


////Add by Yangping
////To fix bug: footer transparent after open camera page
//ion-footer{
//  background: white;
//}
//
//ion-modal {
//  --background: rgba(44, 39, 45, 0.2);
//  &::part(content) {
//    backdrop-filter: blur(6px);
//  }
//  ion-content {
//    --background: white;
//  }
//  ion-datetime {
//    border-radius: 10px;
//    position: absolute;
//    top: 25%;
//    left: 50%;
//    transform: translate(-50%, 0);
//  }
//}

//YP add start
.my-modal-id-css {
  --background: transparent;
  justify-content: center;
  align-items: center;
  display: flex;
  --min-height: 70vh;
  --min-width: 60vw;
  --max-width: 80vw;
  --max-height: 80vh;
}

.photoselect-css {
  --min-height: 100vh;
  --min-width: 100vw;
}

.signin-modal {
  --height: calc(40vh + 10vw);
  --width: calc(40vw + 20vh);
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

  .modal-wrapper {
    .modal-toolbar {
      --background: #FFFFFF;
      --color: white;
      min-height: 3vh;
    }
  }

  ion-modal::part(backdrop) {
    background: rgba(209, 213, 219);
    opacity: 1;
  }
}

html{
    overflow-y: scroll;
    overscroll-behavior-y: none;
    font-size: 18px;
    color-scheme: dark;
}

//YP add end


/** ---------------------------------------------------------------------
 ** ---------------------------------------------------------------------
 * text-align
 ** ---------------------------------------------------------------------
 ---------------------------------------------------------------------**/
.textAlign {
  position: absolute;
  width: 100%;
  top: 50%;
  text-align: center;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.fullscreen-modal .modal-wrapper {
  width: 100%;
  height: 100%;
}

ion-icon.icomoon-icon {
  font-family: 'icomoon' !important; /* 使用 Icomoon 字体 */
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-align: center;
  line-height: 1;
  vertical-align: middle;
  /* 默认图标大小和颜色可以在这里设置 */
  font-size: 24px;
  color: white;
}

/* 通过图标类名设置 Unicode 字符 */
.icomoon-icon.icon-reset::before {
  content: "\e900"; /* 替换为 Icomoon 分配的 Unicode 字符 */
}

.icomoon-icon.icon-rotate-left::before {
  content: "\e901"; /* 替换为 Icomoon 的实际字符 */
}

/* 覆盖 Alert 按钮的样式 */
.custom-alert .alert-button-cancel {
  //--ion-color-primary: #ff3b30; /* 设置取消按钮的文本颜色 */
  color: #cccccc; /* 设置取消按钮的颜色 */
}

.custom-alert .alert-button-confirm {
  //--ion-color-primary: #4a90e2; /* 设置确认按钮的文本颜色 */
  color: #4a90e2; /* 设置确认按钮的颜色 */
}


